import * as crypto from 'crypto';
import { get, isEmpty, isString } from 'lodash';
import moment from 'moment';
import { IDENTITY_DELEGATION_FLAG, USER_INFO, cookieKeysEnum } from '../models/common.models';
import { nameFormatTypeEnum } from './constant';

export const setSession = (key, value) => {
    window.localStorage.setItem(key, value)
};

export const getSession = (key) => {
    return window.localStorage.getItem(key)
};

export const removeSession = (key) => {
    window.localStorage.removeItem(key)
};

export const clearSession = () => {
    window.localStorage.clear()
    window.sessionStorage.clear();
};

export const getFormatDate = (date) => {
    return moment(date).format('MM/DD/YYYY')
};

export const getDomain = () => {
    if (document.domain.length) {
        let parts = document.domain.replace(/^(www\.)/, "").split('.');
        let domain = parts.join('.');
        return domain.replace(/(^\.*)|(\.*$)/g, "");
    }
    return '';
}

export const getBannerDomain = () => {
    // TODO : Get Parent Domain from current domain.
    if (document.domain && document.domain.length) {
        let parts = document.domain.replace(/^(www\.)/, "").split('.');
        while (parts.length > 2) {
            let subdomain = parts.shift();
        }
        let domain = parts.join('.');
        return domain.replace(/(^\.*)|(\.*$)/g, "");
    } // GET Default Doamin.
    return document.domain;
}

export const setCookie = (cookieName, token, hoursToExpire = 1) => {
    let date = new Date();
    date.setTime(date.getTime() + (hoursToExpire * 60 * 60 * 1000));
    let secureFlag = window.location.hostname !== 'localhost' ? ';secure' : '';
    document.cookie = cookieName + "=" + token + "; expires=" + date.toGMTString() + ";domain=" + getDomain() + ";path=/" + secureFlag;
};

export const setBannerCookie = (cookieName, token, toExpire = 1, isHours = true) => {
    let date = new Date();
    if (isHours) {
        date.setTime(date.getTime() + (toExpire * 60 * 60 * 1000));
    } else {
        date.setTime(date.getTime() + (toExpire * 60 * 1000));
    }
    let secureFlag = window.location.hostname !== 'localhost' ? ';secure' : '';
    document.cookie = cookieName + "=" + token + "; expires=" + date.toUTCString() + ";domain=" + getBannerDomain() + ";path=/" + secureFlag;
};

export const setRedirectUrl = (value) => {
    setCookie(cookieKeysEnum["REDIRECT-URL"], value, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
}

export const removeCookie = (cookieName) => {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=" + getDomain() + ";path=/";
};

export const getCookie = (cookieName) => {
    const name = cookieName + "=";
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
        const temp = allCookieArray[i].trim();
        if (temp.indexOf(name) == 0) {
            return temp.substring(name.length, temp.length);
        }
    }
    return "";
}

export const removeAppCookies = (isBeforeLogin = true) => {
    for (let key in cookieKeysEnum) {
        if (isBeforeLogin && (key == "REDIRECT-URL")) {
            continue;
        } else {
            removeCookie(cookieKeysEnum[key]);
        }
    }
}

export const checkCookie = (cookieName) => {
    const name = cookieName + "=";
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
        const temp = allCookieArray[i].trim();
        if (temp.indexOf(name) == 0) {
            return true;
        }
    }
    return false;
}

export const memberNameFormat = (firstname, middlename, lastname, formatType = '') => {
    lastname = lastname ? lastname.trim() : "";
    firstname = firstname ? firstname.trim() : "";
    middlename = middlename ? middlename.trim().substr(0, 1) : "";
    switch (formatType) {
        case nameFormatTypeEnum.FL:
            return `${firstname} ${lastname}`;
        case nameFormatTypeEnum.LF:
            return `${lastname}, ${firstname}`;
        case nameFormatTypeEnum.LFM:
            return `${lastname}, ${firstname} ${middlename}`;
        case nameFormatTypeEnum.FML:
            return `${firstname} ${middlename} ${lastname}`
        default:
            return `${lastname}, ${firstname} ${middlename}`
    }
};

export const memberNickFormat = (nickname, firstname) => {
    nickname = nickname ? nickname.trim() : "";
    firstname = firstname ? firstname.trim() : "";
    if (!isEmpty(nickname)) {
        return nickname;
    }
    if (!isEmpty(firstname)) {
        return firstname.length > 15 ? firstname.substr(0, 15) + '...' : firstname;
    }
    return '';
};

const securityKey = 'aes-256-cbc';

export const encrypt = (text) => {
    try {
        if (text) {
            let iv = crypto.randomBytes(Number(process.env.REACT_APP_STORAGE_IV_LENGTH));
            let cipher = crypto.createCipheriv(securityKey, new Buffer(process.env.REACT_APP_STORAGE_VALUE), iv);
            let encrypted = cipher.update(text);
            encrypted = Buffer.concat([encrypted, cipher.final()]);
            return iv.toString('hex') + ':' + encrypted.toString('hex');
        }
        return text;
    } catch (e) {
        console.log("Exception in Encryption", e);
    }
};

export const decrypt = (text) => {
    try {
        if (text) {
            let textParts = text.split(':');
            if (textParts && textParts.length > 0) {
                let iv = new Buffer(textParts.shift(), 'hex');
                let encryptedText = new Buffer(textParts.join(':'), 'hex');
                let decipher = crypto.createDecipheriv(securityKey, new Buffer(process.env.REACT_APP_STORAGE_VALUE), iv);
                let decrypted = decipher.update(encryptedText);
                decrypted = Buffer.concat([decrypted, decipher.final()]);
                return decrypted.toString();
            }
        }
        return text;
    } catch (e) {
        console.log("Exception in Decryption", e);
    }
};

export const encryptData = (data, key, isLocalStorage = true) => {
    const str = JSON.stringify(data);
    const encrypted = encrypt(str);
    if (isLocalStorage) {
        window.localStorage.setItem(key, encrypted);
    } else {
        window.sessionStorage.setItem(key, encrypted);
    }
    return encrypted;
}

export const decryptData = (key, isLocalStorage = true) => {
    let encrypted = null;
    if (isLocalStorage) {
        encrypted = window.localStorage.getItem(key);
    } else {
        encrypted = window.sessionStorage.getItem(key);
    }
    let decrypted = decrypt(encrypted)
    let decryptedObj = JSON.parse(decrypted);
    return decryptedObj;
}

export const objectKeysToLowerCase = (input) => {
    if (typeof input !== 'object') { return input; }
    if (Array.isArray(input)) { return input.map(objectKeysToLowerCase); }
    return Object.keys(input).reduce((newObj, key) => {
        let val = input[key];
        if (val) {
            newObj[key.toLowerCase()] = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
        } else {
            newObj[key.toLowerCase()] = val;
        }
        return newObj;
    }, {});
};

export const isLoggerEnabled = () => {
    return process.env.REACT_APP_IS_LOGGER_ENABLED === "true";
}

export const getAccountNumberFromUrl = (self) => {
    const { pathname } = self.props.location;
    return pathname && pathname.replace('/roster/', '');
}

// TODO : session storage manager.
export const sessionStorageManager = {
    setItem: (key, value) => window.sessionStorage.setItem(key, value),
    getItem: (key) => window.sessionStorage.getItem(key),
    removeItem: (key) => window.sessionStorage.removeItem(key)
};

export const getcurday = (sp) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //As January is 0.
    let yyyy = today.getFullYear();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return (mm + sp + dd + sp + yyyy);
}

//TODO : get userInfo from cookies.
export const getUserInfo = () => {
    const userInfo = decryptData('user-info');
    if (!isEmpty(userInfo)) {
        return userInfo;
    }
    return null;
}

export const setUserInStorage = (userProfileObj) => {
    encryptData(userProfileObj, 'user-info');
}

export const hideApplicationScroll = (isHide, showBy = null) => {
    const body = document.getElementsByTagName('body')[0];
    if (isHide) {
        body.classList.add("no-scroll");
        window.scrollShowBy = showBy;
    } else {
        if (!window.scrollShowBy || window.scrollShowBy === showBy) {
            body.classList.remove("no-scroll");
        }
    }
}

// TODO : Handle Tab navigation on foucs window.
export const focusOnPopupAndTabNavigationObject = {
    // TODO : Modal Open Handler
    focusOnOpenHandler: (selectorRef, delay = 10) => {
        setTimeout(() => {
            let selector = selectorRef && document.querySelector(selectorRef);
            if (selector) {
                selector.focus()
            }
        }, delay);
    },
    // TODO : Focus on modal Handler
    focusOnKeyPressHandler: (e, windowRef, focusableRef) => {
        if (e && windowRef && focusableRef && e.keyCode === 9) {
            let parentSelector = document.querySelector(windowRef);
            if (parentSelector) {
                let focusable = parentSelector.querySelectorAll(focusableRef);
                if (focusable.length) {
                    let first = focusable[0];
                    let last = focusable[focusable.length - 1];
                    let shift = e.shiftKey;
                    if (shift) {
                        if (e.target === first) { // shift-tab pressed on first input in dialog
                            last.focus();
                            e.preventDefault();
                        }
                    } else {
                        if (e.target === last) { // tab pressed on last input in dialog
                            first.focus();
                            e.preventDefault();
                        }
                    }
                }
            }
        }
    },
    // TODO : Close focus on Handler
    focusOnCloseHandler: (selectorRef) => {
        setTimeout(() => {
            if (selectorRef) {
                let selector = selectorRef && document.querySelector(selectorRef);
                if (selector) {
                    selector.focus();
                }
            }
        }, 100);
    }
}

// TODO : Add script tag in dynamic way.
export const addDocXOnRequest = () => {
    const scripts = [
        "docx/file-save.js",
        "docx/docx.js",
        "docx/report-scripts/Common.js",
        "docx/report-scripts/NewMembers.js",
        "docx/report-scripts/VisitorOnly.js",
        "docx/report-scripts/RosterAndVisitor_v1.js",
        "docx/report-scripts/MembersChangingEmployment.js",
        "docx/report-scripts/CommitteeBalance.js",
        "docx/report-scripts/MembersRemovedFromRoster.js",
        "docx/report-scripts/ProducerWaitList.js",
        "docx/report-scripts/Affiliates.js",
        "docx/report-scripts/MembershipReport.js",
        "docx/report-scripts/RosterReport.js",
        "docx/report-scripts/HonoraryMember.js"
    ];
    if (!window.isDocXLoaded) {
        scripts.forEach(element => {
            const script = document.createElement('script');
            script.setAttribute('src', `${process.env.PUBLIC_URL}/${element}`);
            document.body.appendChild(script);
        });
    }
}

export const generateUUID = (log = false) => {
    if (log) {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

//TODO : check okta storage available in localstorage.
export const isOKTAStorageAvailable = (isAndOperator = true) => {
    if (isAndOperator) {
        // This condition required for validate redirect in public application.
        if (getSession('okta-token-storage') && getSession('okta-cache-storage')) {
            return true;
        }
    } else {
        // This condition required for validate open api call only.
        if (getSession('okta-token-storage') || getSession('okta-cache-storage')) {
            return true
        }
    }
    return false;
}

export const getUUIDNumberFromLocalStorage = url => {
    const getUUIDObject = JSON.parse(window.localStorage.getItem('storeUUIDForAllApiCall'));
    let UuidInEntriesForm = Object.entries(getUUIDObject)
    for (let [key, value] of UuidInEntriesForm) {
        if (key === url) {
            return value;
        }
    }
}

export const cacheManager = {
    getItem: (key, isDecrypt = true) => {
        if (isDecrypt) {
            return decrypt(window.localStorage.getItem(key));
        }
        return window.localStorage.getItem(key)

    },
    getSessionItem: (key, isDecrypt = true) => {
        if (isDecrypt) {
            return decrypt(sessionStorage.getItem('okta-token-storage') ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key));
        }
        return sessionStorage.getItem('okta-token-storage') ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key)

    },
    setItem: (key, value) => {
        let string = isString(value) ? encrypt(value) : encrypt(JSON.stringify(value));
        window.localStorage.setItem(key, string);
    },
    removeItem: (key) => {
        window.localStorage.removeItem(key)
    },
    clearSession: () => {
        window.localStorage.clear()
    }
}

export const checkIdentityDelegation = () => {
    return cacheManager.getItem(USER_INFO) ? get(JSON.parse(cacheManager.getItem(USER_INFO)), IDENTITY_DELEGATION_FLAG) : null;
}

export default {
    setSession,
    getSession,
    removeSession,
    clearSession,
    getFormatDate,
    memberNameFormat,
    objectKeysToLowerCase,
    getCookie,
    setCookie,
    removeCookie,
    checkCookie,
    isLoggerEnabled,
    getAccountNumberFromUrl,
    sessionStorageManager,
    getcurday,
    getUserInfo,
    focusOnPopupAndTabNavigationObject,
    removeAppCookies,
    addDocXOnRequest,
    isOKTAStorageAvailable,
    getUUIDNumberFromLocalStorage,
    setRedirectUrl,
    checkIdentityDelegation,
    cacheManager
}